.tiptap {


  /* Styles for the Tiptap editor content */
  .ProseMirror {
    outline: none !important;
    padding: 1em 10px;

    /* Styles for highlighted search results */
    .search-result {
      background-color: #FFFACD;
    }

    .search-result-current {
      background-color: #FFFF00;
    }
  }
}