.fade {
  position: absolute;
}

.fade-enter,
.fade-appear {
  opacity: 0;
  transform: scale(1.1);
}

.fade-enter-active,
.fade-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms ease-in-out 500ms, transform 1000ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms ease-in-out 0ms, transform 1000ms;
}